import React from 'react'
import '../style/Footer.css'
import { Logo } from '../Assets/index'



const Footer = () => {
    return (
        <div className='body1'>

            <nav className="navbar1 justify-content-center  bg-dark">
                <div className="Footer ">
                    <div className="container ">
                        <div className="row">
                            <div className="col-md-6 col-lg-5 col-12 ft-1 ">
                                <img src={Logo} className="img2" alt="" />
                                <p>Certificates of merit, certificates, or <br />customer comments: help users be more<br />
                                    confident with the products and services<br /> that your website is providing.</p>

                                {/* Footericon */}
                                <div className='Footer-icon' >
                                    <i className="fa-brands fa-facebook "></i>
                                    <i className="fa-brands fa-twitter "></i>
                                    <i className="fa-brands fa-instagram "></i>
                                    <i className="fa-brands fa-youtube "></i>

                                </div>
                            </div>

                            <div className="col-md-6 col-lg-3 col-12 ft-2">
                                <h5 ><b>AGNI SERVICE</b></h5>
                                <ul>
                                    <a className="nav-link " aria-current="page" href="/"> Agni Gold </a>
                                    <a className="nav-link " href="/">Agni Retail</a>

                                    <a className="nav-link " href="/">Web Designs</a>
                                    <a className="nav-link " href="/">E - Commerce Website</a>
                                    <a className="nav-link " href="/">Web Hosting</a>
                                    <a className="nav-link " href="/">Android Application</a>

                                </ul>
                            </div>

                            {/* <div className=" col-md-6 col-lg-4 col-12 ft-3">

                                <h5><b>CONTACT US</b></h5>
                                <p> <i className="fa-solid fa-phone-volume fa-beat-fade"></i>   + 0452-4524203</p>
                                <p><i className="fa-solid fa-envelope fa-beat-fade"></i>  agnisoftwaresolutions@gmail.com</p>
                                <p> <i className="fa-sharp fa-solid fa-location-dot fa-beat-fade"></i>  Agni Software Solutions,<br />Thillai Nagar,Sammattipuram,<br /> Madurai - 625016,Tamilnadu, India.</p>

                            </div> */}


                            <div className=" col-md-6 col-lg-4 col-12 ft-3  ">

                                <h5><b>CONTACT US</b></h5>

                                <div className='ft-3-cont'>


                                    <div className='ft-cont mt-4'>
                                        <div className='footer-cont-icon1'>
                                            <i className="fa-solid fa-phone-volume mt-1 " style={{ color: "white" }}></i>
                                        </div>
                                        &nbsp; &nbsp; &nbsp;  &nbsp;&nbsp;
                                        <div className='footer-cont-title1'>
                                            <p>+ 0452-4524203</p>
                                        </div>
                                    </div>


                                    <div className='ft-cont'>

                                        <div className='footer-cont-icon2'>

                                            <i className="fa-solid fa-envelope mt-1" style={{ color: "white" }}></i>

                                        </div>
                                        &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;
                                        <div className='footer-cont-title2'>

                                            <p>agnisoftwaresolutions@gmail.com</p>


                                        </div>
                                    </div>


                                    <div className='ft-cont'>

                                        <div className='footer-cont-icon3'>
                                            <i className="fa-sharp fa-solid fa-location-dot mt-1" style={{ color: "white" }}></i>

                                        </div>
                                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                                        <div className='footer-cont-title3'>
                                            <p >    Agni Software Solutions,<br /> Thillai Nagar,Sammattipuram,<br /> Madurai - 625016,Tamilnadu, India.</p>

                                        </div>

                                    </div>

                                </div>





                            </div>


                        </div>
                    </div>
                </div>
            </nav>
            <nav className='copy bg-dark  ' >

                <div className="  text-center text-white">
                    <p className=''> &copy; 2023 Agni Software Solutions. All Rights Reserved. </p>
                </div>
            </nav>


        </div>
    )

}

export default Footer;