import React, { useState, useEffect, useRef } from 'react'
import { Opent, Sm, TS } from '../Assets/index'
import '../style/careers.css'
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import Aos from 'aos';
import 'aos/dist/aos.css';




const Careers = () => {
    const [NetVisible, setNetVisible] = useState(false);
    const [WebVisible, setWebVisible] = useState(false);
    const [AccVisible, setAccVisible] = useState(false);
    const [AnotherVisible, setAnotherVisible] = useState(false);
    const cardRef = useRef(null);

    useEffect(() => {
        Aos.init({ disable: 'mobile' });

        const handleClickOutside = (event) => {
            if (cardRef.current && !cardRef.current.contains(event.target)) {
                setNetVisible(false);
                setWebVisible(false);
                setAccVisible(false);
                setAnotherVisible(false);
            }
        };

        document.addEventListener('mouseup', handleClickOutside);

        return () => {
            document.removeEventListener('mouseup', handleClickOutside);
        };
    }, []);

    const shownet = () => {
        setNetVisible(!NetVisible);
        setWebVisible(false);
    };

    const showweb = () => {
        setWebVisible(!WebVisible);
        setNetVisible(false);
    };

    const showAcc = () => {
        setAccVisible(!AccVisible);
        setNetVisible(false);
        setWebVisible(false);
    };

    const showAnother = () => {
        setAnotherVisible(!AnotherVisible);
        setNetVisible(false);
        setWebVisible(false);
        setAccVisible(false);
    };


    return (

        <div className='careers-body'>


            <nav data-aos="zoom-in-down" data-aos-offset="200" data-aos-duration="600" className='contact-nav' style={{ backgroundColor: "white" }}>

                <div className='container' >

                    <img className='contact-title' src={Opent} alt='' />
                </div>

            </nav>




            <div className='mb-5'>
                <div className='container'>
                    <div className=''>
                        <div className='row align-items-center justify-content-center'>
                            <div className={`col${NetVisible ? '-12' : '-6'}`}>
                                <div className='net-div '>
                                    <div className='title-hed'>
                                        Net
                                    </div>
                                    <div className='down'>
                                        <FontAwesomeIcon icon={faAngleDown} onClick={shownet} />
                                    </div>
                                </div>
                            </div>

                            {NetVisible &&  (
                                <div data-aos="zoom-in-down" data-aos-offset="200" data-aos-duration="400" className='col-12'>
                                    <div className="card-NET" ref={cardRef}>
                                        <div className="card-imgandtitle2">
                                            <div className="col-lg-6 col-12">
                                                <img className="card-NET-image" alt="" src={TS} />
                                            </div>
                                            <div className="card-NET-content col-lg-6 col-12">
                                                <p className="card-NET-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.</p>
                                                <div className="file-container d-flex">
                                                    <input type="file" id="fileInput" className="custom-file-input" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className=''>
                        <div className='row align-items-center justify-content-center'>
                            <div className={`col${WebVisible ? '-12' : '-6'}`}>
                                <div className='net-div'>
                                    <div  className='title-hed'>
                                        Web
                                    </div>
                                    <div className='down'>
                                        <FontAwesomeIcon icon={faAngleDown} onClick={showweb} />
                                    </div>
                                </div>
                            </div>

                            {WebVisible && (
                                <div data-aos="zoom-in-down" data-aos-offset="200" data-aos-duration="400" className='col-12'>
                                    <div className="card-NET" ref={cardRef}>
                                        <div className="card-imgandtitle2">
                                            <div className="col-lg-6 col-12">
                                                <img className="card-NET-image" alt="" src={TS} />
                                            </div>
                                            <div className="card-NET-content col-lg-6 col-12">
                                                <p className="card-NET-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.</p>
                                                <div className="file-container d-flex">
                                                    <input type="file" id="fileInput" className="custom-file-input" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>


                    <div className=''>
                        <div className='row align-items-center justify-content-center'>
                            <div className={`col${AccVisible ? '-12' : '-6'}`}>
                                <div className='net-div'>
                                    <div  className='title-hed'>
                                        Acc
                                    </div>
                                    <div className='down'>
                                        <FontAwesomeIcon icon={faAngleDown} onClick={showAcc} />
                                    </div>
                                </div>
                            </div>

                            {AccVisible && (
                                <div data-aos="zoom-in-down" data-aos-offset="200" data-aos-duration="400" className='col-12'>
                                    <div className="card-NET" ref={cardRef}>
                                        <div className="card-imgandtitle2">
                                            <div className="col-lg-6 col-12">
                                                <img className="card-NET-image" alt="" src={TS} />
                                            </div>
                                            <div className="card-NET-content col-lg-6 col-12">
                                                <p className="card-NET-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.</p>
                                                <div className="file-container d-flex">
                                                    <input type="file" id="fileInput" className="custom-file-input" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className=''>
                        <div className='row align-items-center justify-content-center'>
                            <div className={`col${AnotherVisible ? '-12' : '-6'}`}>
                                <div className='net-div'>
                                    <div  className='title-hed'>
                                        Another
                                    </div>
                                    <div className='down'>
                                        <FontAwesomeIcon icon={faAngleDown} onClick={showAnother} />
                                    </div>
                                </div>
                            </div>

                            {AnotherVisible && (
                                <div data-aos="zoom-in-down" data-aos-offset="200" data-aos-duration="400" className='col-12'>
                                    <div className="card-NET" ref={cardRef}>
                                        <div className="card-imgandtitle2">
                                            <div className="col-lg-6 col-12">
                                                <img className="card-NET-image" alt="" src={TS} />
                                            </div>
                                            <div className="card-NET-content col-lg-6 col-12">
                                                <p className="card-NET-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.</p>
                                                <div className="file-container d-flex">
                                                    <input type="file" id="fileInput" className="custom-file-input" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>














        </div>



    )
}

export default Careers

{/* <Modal show={net} onHide={Closenet}>
<Modal.Header closeButton>
    <Modal.Title>More Details</Modal.Title>
</Modal.Header>
<Modal.Body>
    <form>
        <label>Example Input:</label>
        <input type="text" />
    </form>
</Modal.Body>
<Modal.Footer>
</Modal.Footer>
</Modal> */}