import React, { useEffect } from 'react';
import Aos from 'aos'
import 'aos/dist/aos.css'
import '../style/Finance.css'
import { finance } from "../Assets/index";


const Finance = () => {

    useEffect(() => {

        Aos.init({ disable: 'mobile' });

    }, [])
    return (


        <div className="">


            <nav className="navbar3 mt-5">
                <div className="container  card-container">
                    <img className="Retail-title" src={finance} alt="" />
                </div>
            </nav>



            <div className="d-flex">



                <div className='container'>
                    <div  data-aos="flip-left" data-aos-offset="200"  data-aos-duration="800" className="  card-fin mt-5">

                        <div className=" img img1">
                            <p>
                               Finance
                            </p>
                        </div>
                        <div className="card__content">
                            <p className="name">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.
                            </p>
                            <div className="button-container d-flex">
                                <button className="nav-btn custom-but "> More Detail</button>
                            </div>
                        </div>
                        <div className="img img2"></div>

                    </div>

                </div>





                <div className='container'>
                    <div data-aos="flip-right" data-aos-offset="200"  data-aos-duration="800" className="  card-fin mt-5 ">

                        <div className=" img img1">
                            <p>
                               Finance
                            </p>
                        </div>
                        <div className="card__content">
                            <p className="name">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.
                            </p>
                            {/* <span className="job">Software engineer</span> */}
                            <div className="button-container d-flex">
                                <button className="nav-btn custom-but "> More Detail</button>
                            </div>
                        </div>
                        <div className="img img2"></div>

                    </div>

                </div>

            </div>


            <div className="d-flex">

                <div className='container'>
                    <div data-aos="flip-left" data-aos-offset="200"  data-aos-duration="800" className="  card-fin mt-5 ">

                        <div className=" img img1">
                            <p>
                               Finance
                            </p>
                        </div>
                        <div className="card__content">
                            <p className="name">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.
                            </p>
                            {/* <span className="job">Software engineer</span> */}
                            <div className="button-container d-flex">
                                <button className="nav-btn custom-but "> More Detail</button>
                            </div>
                        </div>
                        <div className="img img2"></div>

                    </div>

                </div>



                <div className='container'>
                    <div data-aos="flip-right" data-aos-offset="200"  data-aos-duration="800" className="  card-fin mt-5 mb-5">

                        <div className=" img img1">
                            <p>
                               Finance
                            </p>
                        </div>
                        <div className="card__content">
                            <p className="name">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.
                            </p>
                            {/* <span className="job">Software engineer</span> */}
                            <div className="button-container d-flex">
                                <button className="nav-btn custom-but "> More Detail</button>
                            </div>
                        </div>
                        <div className="img img2"></div>

                    </div>

                </div>

            </div>







        </div>

    )
}

export default Finance