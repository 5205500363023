import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NavDropdown, Button, Modal } from 'react-bootstrap';
import axios from 'axios';



// import { Splide, SplideSlide } from '@splidejs/react-splide';
// import '@splidejs/react-splide/css';
import { Logo } from '../Assets/index'
import '../style/header.css'
import '../pages/home'

const Header = () => {
  // const location = useLocation();
  // const [headerColor, setHeaderColor] = useState('');
  const [contact, SetContact] = useState(false)
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const showContact = () => {

    SetContact(true);
  };

  const CloseContact = () => {

    SetContact(false);
  }


  // useEffect(() => {

  //   const colorMap = {
  //     '/Contactus': 'linear-gradient(to right, #FF7E5F, #feb47b)',
  //     '/Aboutus': 'linear-gradient(to right, #3494E6, #EC6EAD)',

  //   };


  //   setHeaderColor(colorMap[location.pathname] || '');
  // }, [location.pathname]);



  const emailAddress = 'agnisoftwaresolutions@gmail.com'; // Replace with the email address you want to link

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    city: '',
    remark: '',
  });


  const [timing, setTiming] = useState('AM');
  const [selectedCategory, setSelectedCategory] = useState(''); // State for selected project category


  const [errors, setErrors] = useState({
    name: '',
    email: '',
    mobile: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const validateForm = () => {
      let valid = true;
      const newErrors = { ...errors };

      if (!/^[a-zA-Z\s]+$/.test(formData.name)) {
        newErrors.name = 'Only characters are allowed in the Name field.';
        valid = false;
      } else {
        newErrors.name = '';
      }

      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
        newErrors.email = 'Invalid email format.';
        valid = false;
      } else {
        newErrors.email = '';
      }

      if (!/^\d{10}$/.test(formData.mobile)) {
        newErrors.mobile = 'Mobile number must be exactly 10 digits.';
        valid = false;
      } else {
        newErrors.mobile = '';
      }

      setErrors(newErrors);
      return valid;
    };

    try {
      const response = await axios.post('http://192.168.0.104:8012/contact/us', formData);

      if (response.status === 200) {
        // Clear the form after successful submission
        setFormData({
          name: '',
          email: '',
          mobile: '',
          city: '',
          remark: '',
        });
        setErrors({}); // Clear any previous errors
      } else {
        // Handle any API errors here
      }

      console.log('Server response:', response.data);
    } catch (error) {

      console.error('Error:', error);
    }



    if (validateForm()) {
      // Submit the form data to your server or perform any other action here
      console.log('Form is valid. Submitting data:', formData);
    } else {
      console.log('Form has errors. Please correct them.');
    }
  };


  return (



    <div className='a1'>
      <nav className={`navbar navbar-expand-lg navbar-dark bg-dark ${isMenuOpen ? 'show' : ''}`}>
        {/* <nav className={`navbar navbar-expand-lg  ${isMenuOpen ? 'show' : ''}`} style={{ background: headerColor }}> */}

        <div className="container">


          <div className="col-lg-4">
            <img className="logo" src={Logo} alt="" />
          </div>

          <div className="col-lg-1"></div>

          <div className={`col-lg-8 collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarNavAltMarkup">
            <div className="navbar-nav">
              <Link className="nav-link ms-2" to="/" onClick={closeMenu}><strong>Home</strong></Link>
              {/* <Link className="nav-link ms-2" to="products" onClick={closeMenu}><strong>Products</strong></Link> */}

              <NavDropdown title="Products" id="basic-nav-dropdown" className='ms-2'>
                <div className="custom-dropdown-menu">
                  {/* <NavDropdown.Item as={Link} to="Retailmt" onClick={closeMenu}>Retail Management</NavDropdown.Item> */}
                  <NavDropdown.Item as={Link} to="" onClick={closeMenu}>Retail Management</NavDropdown.Item>
                  {/* <NavDropdown.Item as={Link} to="Jewellerymt" onClick={closeMenu}>Jewellery Management </NavDropdown.Item> */}
                  <NavDropdown.Item as={Link} to="" onClick={closeMenu}>Jewellery Management </NavDropdown.Item>

                  {/* <NavDropdown.Item as={Link} to="finance" onClick={closeMenu}>Finance management</NavDropdown.Item> */}
                  <NavDropdown.Item as={Link} to="" onClick={closeMenu}>Finance management</NavDropdown.Item>

                  <NavDropdown.Item as={Link} to="MobileApl" onClick={closeMenu}>Mobile App Development</NavDropdown.Item>

                </div>
              </NavDropdown>

              {/* <Link className="nav-link ms-2" to="services" onClick={closeMenu}><strong>Services</strong></Link> */}
              <Link className="nav-link ms-2" to="" onClick={closeMenu}><strong>Services</strong></Link>
              {/* <Link className="nav-link ms-2" to="careers" onClick={closeMenu}><strong>Careers</strong></Link> */}
              <Link className="nav-link ms-2" to="" onClick={closeMenu}><strong>Careers</strong></Link>

              {/* <Link className="nav-link ms-2" to="Aboutus" onClick={closeMenu}><strong>About</strong></Link> */}
              <Link className="nav-link ms-2" to="" onClick={closeMenu}><strong>About</strong></Link>

              {/* <Link className="nav-link ms-2" to="Contactus" onClick={closeMenu}><strong>Contact</strong></Link> */}
              <Link className="nav-link ms-2" to="" onClick={closeMenu}><strong>Contact</strong></Link>

              {/* <Link className="nav-link ms-2" onClick={() => { showContact(); closeMenu(); }}><strong>Demo</strong></Link> */}
              <button className='nav-btn ms-3' onClick={() => window.open('https://agnisofterp.com/LoginForm.html', '_blank')}>LOGIN</button>
            </div>
            


            <Modal className='modal-f' show={contact} onHide={CloseContact}>
              <Modal.Header className='modal-tit' closeButton>

                Demo Details

              </Modal.Header>
              <Modal.Body>

                <form className="form form-size" onSubmit={handleSubmit}>


                  <div className="">
                    <label htmlFor="name" className="">
                      Name
                    </label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="bi bi-person-fill"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder='Enter Your Full Name'
                      ></input>
                    </div>
                    <div className="text-danger">{errors.name}</div>
                  </div>


                  {/* <div className="mb-3">
                <label htmlFor="email" className="">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                <div className="text-danger">{errors.email}</div>
              </div> */}


                  <div className="">
                    <label htmlFor="email" className="">
                      Email
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="bi bi-envelope"></i>
                      </span>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder='Enter Your Email'
                      />
                    </div>
                    <div className="text-danger">{errors.email}</div>
                  </div>





                  <div className="">
                    <label htmlFor="mobile" className="">
                      Mobile Number
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="bi bi-telephone"></i>
                      </span>
                      <input
                        type="tel"
                        className="form-control"
                        id="mobile"
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleChange}
                        placeholder='Enter Your Mobile Number'
                      />
                    </div>
                    <div className="text-danger">{errors.mobile}</div>
                  </div>



                  <div className="">
                    <label htmlFor="city" className="">
                      City
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="bi bi-geo-alt-fill"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        id="city"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        placeholder='Enter Your City'
                      />
                    </div>
                  </div>



                  <div className="">
                    <label htmlFor="category" className="">
                      Category of   Project
                    </label>
                    <select
                      className="form-select"
                      id="category"
                      name="category"

                      value={selectedCategory}
                      onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                      <option value="" disabled selected>
                        Select a category
                      </option>
                      <option value="Retail">Retail</option>
                      <option value="Retail Shop">Retail Shops</option>
                      <option value="Jewellery Retail">Jewellery Retail</option>
                      <option value="Jewellery Whole">Jewellery Wholesale</option>
                      <option value="Whole sale">Wholesale</option>
                      <option value="App">Android App</option>


                      {/* Add more categories as needed */}
                    </select>
                  </div>


                  <div className="">
                    <label htmlFor="timing" className="">
                      Select Timing
                    </label>
                    <select
                      className="form-select"
                      id="timing"
                      name="timing"
                      value={timing}
                      onChange={(e) => setTiming(e.target.value)}
                    >
                      {/* <option value="" disabled selected>
                        Choose a timing
                      </option> */}
                      <option value="AM">10:30 AM to 12:30 PM </option>
                      <option value="PM">02:00 PM to 04:00 PM</option>
                    </select>
                  </div>



                  <div className="">
                    <label htmlFor="remark" className="">
                      Subject/Remark
                    </label>
                    <textarea
                      className="form-control"
                      id="remark"
                      name="remark"
                      value={formData.remark}
                      onChange={handleChange}
                      placeholder='Your Remark'
                    />
                  </div>
                  <div className="d-flex justify-content-center mt-3">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </Modal.Body>
              <Modal.Footer>

              </Modal.Footer>
            </Modal>
          </div>

          <button className="navbar-toggler" type="button" onClick={toggleMenu}>
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
      </nav>
    </div>

  )
}

export default Header