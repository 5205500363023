


export const Example = [

    
    {
 
       id: 0,
        title: 'AGNI GOLD',
       subtitle: 'SOFTWARE',
       content1: 'AGNI GOLD HAS DRILLED DOWN ITSELF INTO JEWELLERY INDUSTRY TOGIVE SOFT SOLUTION FOR HARD BUSINESS ASPECTS.THE IN DEPTH JEWELLERY KNOWLEDGE HELPS TO GIVE QUICKLY & CONTROLLABLE SOLUTIONS AS PER JEWELLERY BUSINESS TRENDS.',
       content2: 'AGNI GOLD IS A TAILOR MADE SOFTWARE WHICH SUITES FOR ALL JEWELLERY BUSINESS. AGNI GOLD COVERS ALL JEWELLERY NEEDS WITH ENHANCED FEATURES.'
    }
   
 ]
 export const Retail = [

  
   {

      id: 1,
       title: 'AGNI RETAIL',
      subtitle: 'SOFTWARE',
      content1: 'AGNI RETAIL IS A FLEXIBLE USER FRIENDLY, READY TO USE SOFTWARE FOR RETAILING BUSINESS. THE SOFTWARE IS DESIGNED TO MEET THE NEEDS OF RETAIL MARKET WHICH RUNS INDEPENDENTLY OR MULTI CHAIN OF STORES. IN DEPTH STUDY OF RETAIL MARKET STARTEGY HAS HELPED TO PROVIDE SOLUTIONS TO STREAMLINE BUSINESS PROCEDURES WITH INCREASED ORGANIZATIONAL EFFICIENCY.',
      content2: 'AGNI RETAIL, AS THE NAME SUGGESTS, WILL BE THE MOST SUITABLE PACKAGE FOR YOUR BUSINESS.'

   }

 ]
 
 export const E_com = [
 
   {

      id: 2,
       title: 'E-COMMERCE',
      subtitle: 'WEBSITE',
      content1: 'E-COMMERCE IS THE ACTIVITY OF BUYING OR SELLING OF PRODUCTS ON ONLINE SERVICES OR OVER THE INTERNET. ELECTRONIC COMMERCE DRAWS ON TECHNOLOGIES SUCH AS MOBILE COMMERCE, ELECTRONIC FUNDS TRANSFER, SUPPLY CHAIN MANAGEMENT, INTERNET MARKETING, ONLINE TRANSACTION PROCESSING,.',
      content2: 'ELECTRONIC DATA INTERCHANGE (EDI), INVENTORY MANAGEMENTSYSTEMS,AND AUTOMATED DATA COLLECTION SYSTEMS.',
      content3: 'MODERN ELECTRONIC COMMERCE TYPICALLY USES THE WORLD WIDE WEB FOR AT LEAST ONE PART OF THE TRANSACTIONS LIFE CYCLE ALTHOUGH IT MAY ALSO USE OTHER TECHNOLOGIES SUCH AS E-MAIL.'

   }

 ]


 
export const Software = [

 
    {
 
       id: 3,
        title: 'AGNI SOFTWARE',
       subtitle: 'SOFTWARE',
       content1: 'AGNI GOLD HAS DRILLED DOWN ITSELF INTO JEWELLERY INDUSTRY TOGIVE SOFT SOLUTION FOR HARD BUSINESS ASPECTS.THE IN DEPTH JEWELLERY KNOWLEDGE HELPS TO GIVE QUICKLY & CONTROLLABLE SOLUTIONS AS PER JEWELLERY BUSINESS TRENDS.',
       content2: 'AGNI GOLD IS A TAILOR MADE SOFTWARE WHICH SUITES FOR ALL JEWELLERY BUSINESS. AGNI GOLD COVERS ALL JEWELLERY NEEDS WITH ENHANCED FEATURES.'
    }
   
 ]