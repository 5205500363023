import React, { useState,useEffect } from 'react';
import Aos from 'aos'
import 'aos/dist/aos.css'

import '../style/Retail.css'
import { HR, HW, Rtitle, Sm, TS, Tes, Wsales, bakery, bar, book, chicken, fancy, fruite, metal, mobile, parking, room, shoes, waiting } from "../Assets/index";

import { Button, Modal } from 'react-bootstrap';

const Retail = () => {

    

useEffect(() => {

    Aos.init({disable: 'mobile'});

},[])

    const [showModal, setShowModal] = useState(false);
    const [hardWare, setHardWare] = useState(false);
    const [Teashop, setTeaShop] = useState(false);
    const [Textile, setTextile] = useState(false);
    const [Mobile,setMobile] = useState(false);
    const [WholeSale , SetWholeSale] = useState (false)
 
    const handleShowModal = () => {
        setShowModal(true);

    };

    const handleCloseModal = () => {
        setShowModal(false);

    }

    const showHardware = () => {

        setHardWare(true);
    };

    const CloseHardware = () => {

        setHardWare(false);
    }

    const showTeashop = () => {
        setTeaShop(true);
    }
    const CloseTeashop = () => {
        setTeaShop(false);
    }

    const ShowTextile = () => {
        setTextile(true);
    }
    const CloseTextile = () => {
        setTextile(false);
    }

    const ShowMobile = () => {
        setMobile(true);
    }
    const CloseMobile = () => {
        setMobile(false);
    }

    const ShoWholesale = () => {
        SetWholeSale(true);
    }

    const CloseWholeSale = () => {
        SetWholeSale(false);
    }

    


    return (

        <div >

            <nav className="navbar3 mt-5">
                <div className="container  card-container">
                    <img className="Retail-title" src={Rtitle} alt="" />
                </div>
            </nav>



            <div>

                <div className="container mt-5">

                    <div className="card-cont-all">



                        <div data-aos="fade-right" data-aos-delay="100" className="card-re">
                            <div className="card-re-title ">
                                <h2 className="card-re-title1 ">Super Market</h2>
                            </div>
                            <img className="card-re-image" alt="" src={Sm} />
                            <div className="card-re-content">
                                <p className="card-re-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.</p>
                                <div className="button-container d-flex">
                                    <button className="nav-btn custom-button" onClick={handleShowModal}> More Detail</button>
                                </div>

                                <Modal show={showModal} onHide={handleCloseModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>More Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {/* Your form or additional details can be added here */}
                                {/* Example: */}
                                <form>
                                    <label>Example Input:</label>
                                    <input type="text" />
                                    {/* Add more form fields or information here */}
                                </form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseModal}>
                                    Close
                                </Button>

                            </Modal.Footer>
                        </Modal>

                            </div>
                        </div>


                       




                        <div className="card-re">
                            <div className="card-re-title ">
                                <h2 className="card-re-title1 ">Hardware Shops</h2>
                            </div>
                            <img className="card-re-image" alt="" src={HW} />
                            <div className="card-re-content">
                                <p className="card-re-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.</p>
                                <div className="button-container d-flex">
                                    <button className="nav-btn custom-button" onClick={showHardware}> More Detail</button>
                                </div>

                                <Modal show={hardWare} onHide={CloseHardware}>
                            <Modal.Header closeButton>
                                <Modal.Title>More Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {/* Your form or additional details can be added here */}
                                {/* Example: */}
                                <form>
                                    <label>Example Input:</label>
                                    <input type="text" />
                                    {/* Add more form fields or information here */}
                                </form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={CloseHardware}>
                                    Close
                                </Button>

                            </Modal.Footer>
                        </Modal>

                            </div>
                        </div>



                       




                        <div data-aos="fade-left" data-aos-delay="100"  className="card-re" >
                            <div className="card-re-title ">
                                <h2 className="card-re-title1 ">Tea Shops</h2>
                            </div>
                            <img className="card-re-image" alt="" src={TS} />
                            <div className="card-re-content">
                                <p className="card-re-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.</p>
                                <div className="button-container d-flex">
                                    <button className="nav-btn custom-button" onClick={showTeashop}> More Detail</button>
                                </div>
                                <Modal show={Teashop} onHide={CloseTeashop}>
                            <Modal.Header closeButton>
                                <Modal.Title>More Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {/* Your form or additional details can be added here */}
                                {/* Example: */}
                                <form>
                                    <label>Example Input:</label>
                                    <input type="text" />
                                    {/* Add more form fields or information here */}
                                </form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={CloseTeashop}>
                                    Close
                                </Button>

                            </Modal.Footer>
                        </Modal>

                            </div>
                        </div>

                       



                    </div>

                </div>



                <div className="container mt-5">

                    <div className="card-cont-all">



                        <div data-aos="fade-right" data-aos-delay="100" className="card-re">
                            <div className="card-re-title ">
                                <h2 className="card-re-title1 ">Textile's  Store</h2>
                            </div>
                            <img className="card-re-image" alt="" src={Tes} />
                            <div className="card-re-content">
                                <p className="card-re-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.</p>
                                <div className="button-container d-flex">
                                    <button className="nav-btn custom-button" onClick={ShowTextile}> More Detail</button>
                                </div>

                                <Modal show={Textile} onHide={CloseTextile}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>More Details</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {/* Your form or additional details can be added here */}
                                        {/* Example: */}
                                        <form>
                                            <label>Example Input:</label>
                                            <input type="text" />
                                            {/* Add more form fields or information here */}
                                        </form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={CloseTextile}>
                                            Close
                                        </Button>

                                    </Modal.Footer>
                                </Modal>

                            </div>
                        </div>

                        <div className="card-re">
                            <div className="card-re-title ">
                                <h2 className="card-re-title1 ">Mobile Shops</h2>
                            </div>
                            <img className="card-re-image" alt="" src={mobile} />
                            <div className="card-re-content">
                                <p className="card-re-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.</p>
                                <div className="button-container d-flex">
                                    <button className="nav-btn custom-button" onClick={ShowMobile}> More Detail</button>
                                </div>


                                <Modal show={Mobile} onHide={CloseMobile}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>More Details</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {/* Your form or additional details can be added here */}
                                        {/* Example: */}
                                        <form>
                                            <label>Example Input:</label>
                                            <input type="text" />
                                            {/* Add more form fields or information here */}
                                        </form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={CloseMobile}>
                                            Close
                                        </Button>

                                    </Modal.Footer>
                                </Modal>


                            </div>
                        </div>

                        <div data-aos="fade-left" data-aos-delay="100" className="card-re">
                            <div className="card-re-title ">
                                <h2 className="card-re-title1 ">W-sales Shops</h2>
                            </div>
                            <img className="card-re-image" alt="" src={Wsales} />
                            <div className="card-re-content">
                                <p className="card-re-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.</p>
                                <div className="button-container d-flex">
                                    <button className="nav-btn custom-button" onClick={ShoWholesale}> More Detail</button>
                                </div>
                                <Modal show={WholeSale} onHide={CloseWholeSale}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>More Details</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {/* Your form or additional details can be added here */}
                                        {/* Example: */}
                                        <form>
                                            <label>Example Input:</label>
                                            <input type="text" />
                                            {/* Add more form fields or information here */}
                                        </form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={CloseWholeSale}>
                                            Close
                                        </Button>

                                    </Modal.Footer>
                                </Modal>


                            </div>
                        </div>



                    </div>





                </div>



                <div className="container mt-5">

                    <div className="card-cont-all">



                        <div data-aos="fade-right" data-aos-delay="100" className="card-re">
                            <div className="card-re-title ">
                                <h2 className="card-re-title1 ">Book Shops</h2>
                            </div>
                            <img className="card-re-image" alt="" src={book} />
                            <div className="card-re-content">
                                <p className="card-re-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.</p>
                                <div className="button-container d-flex">
                                    <button className="nav-btn custom-button"> More Detail</button>
                                </div>


                            </div>
                        </div>

                        <div className="card-re">
                            <div className="card-re-title ">
                                <h2 className="card-re-title1 ">Fancy Store's</h2>
                            </div>
                            <img className="card-re-image" alt="" src={fancy} />
                            <div className="card-re-content">
                                <p className="card-re-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.</p>
                                <div className="button-container d-flex">
                                    <button className="nav-btn custom-button"> More Detail</button>
                                </div>


                            </div>
                        </div>

                        <div data-aos="fade-left" data-aos-delay="100" className="card-re">
                            <div className="card-re-title ">
                                <h2 className="card-re-title1 ">Fruites Shops</h2>
                            </div>
                            <img className="card-re-image" alt="" src={fruite} />
                            <div className="card-re-content">
                                <p className="card-re-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.</p>
                                <div className="button-container d-flex">
                                    <button className="nav-btn custom-button"> More Detail</button>
                                </div>


                            </div>
                        </div>




                    </div>





                </div>



                <div className="container mt-5 mb-5">

                    <div className="card-cont-all">



                        <div data-aos="fade-right" data-aos-delay="100" className="card-re">
                            <div className="card-re-title ">
                                <h2 className="card-re-title1 ">Baceery Shop</h2>
                            </div>
                            <img className="card-re-image" alt="" src={bakery} />
                            <div className="card-re-content">
                                <p className="card-re-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.</p>
                                <div className="button-container d-flex">
                                    <button className="nav-btn custom-button"> More Detail</button>
                                </div>


                            </div>
                        </div>

                        <div className="card-re">

                            <div className="card-re-title ">
                                <h2 className="card-re-title1 ">Bar Details</h2>
                            </div>
                            <img className="card-re-image" alt="" src={bar} />
                            <div className="card-re-content">
                                <p className="card-re-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.</p>
                                <div className="button-container d-flex">
                                    <button className="nav-btn custom-button"> More Detail</button>
                                </div>

                            </div>
                        </div>

                        <div data-aos="fade-left" data-aos-delay="100" className="card-re">
                            <div className="card-re-title ">
                                <h2 className="card-re-title1 ">Metal Shops</h2>
                            </div>
                            <img className="card-re-image" alt="" src={metal} />
                            <div className="card-re-content">
                                <p className="card-re-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.</p>
                                <div className="button-container d-flex">
                                    <button className="nav-btn custom-button"> More Detail</button>
                                </div>


                            </div>
                        </div>




                    </div>





                </div>



                <div className="container mt-5 mb-5">

                    <div className="card-cont-all">



                        <div data-aos="fade-right" data-aos-delay="100" className="card-re">
                            <div className="card-re-title ">
                                <h2 className="card-re-title1 ">Chicken Shop</h2>
                            </div>
                            <img className="card-re-image" alt="" src={chicken} />
                            <div className="card-re-content">
                                <p className="card-re-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.</p>
                                <div className="button-container d-flex">
                                    <button className="nav-btn custom-button"> More Detail</button>
                                </div>


                            </div>
                        </div>

                        <div className="card-re">

                            <div className="card-re-title ">
                                <h2 className="card-re-title1">Hotel & Restaurant</h2>
                            </div>
                            <img className="card-re-image" alt="" src={HR} />
                            <div className="card-re-content">
                                <p className="card-re-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.</p>
                                <div className="button-container d-flex">
                                    <button className="nav-btn custom-button"> More Detail</button>
                                </div>

                            </div>
                        </div>

                        <div data-aos="fade-left" data-aos-delay="100" className="card-re">
                            <div className="card-re-title ">
                                <h2 className="card-re-title1 ">Room Booking</h2>
                            </div>
                            <img className="card-re-image" alt="" src={room} />
                            <div className="card-re-content">
                                <p className="card-re-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.</p>
                                <div className="button-container d-flex">
                                    <button className="nav-btn custom-button"> More Detail</button>
                                </div>


                            </div>
                        </div>




                    </div>

                </div>



                <div className="container mt-5 mb-5">

                    <div className="card-cont-all">



                        <div data-aos="fade-right" data-aos-delay="100" className="card-re">
                            <div className="card-re-title ">
                                <h2 className="card-re-title1 ">Waiting Hall</h2>
                            </div>
                            <img className="card-re-image" alt="" src={waiting} />
                            <div className="card-re-content">
                                <p className="card-re-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.</p>
                                <div className="button-container d-flex">
                                    <button className="nav-btn custom-button"> More Detail</button>
                                </div>


                            </div>
                        </div>

                        <div className="card-re">
                            <div className="card-re-title ">
                                <h2 className="card-re-title1 ">Parking Mangement</h2>
                            </div>
                            <img className="card-re-image" alt="" src={parking} />
                            <div className="card-re-content">
                                <p className="card-re-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.</p>
                                <div className="button-container d-flex">
                                    <button className="nav-btn custom-button"> More Detail</button>
                                </div>


                            </div>
                        </div>

                        <div data-aos="fade-left" data-aos-delay="100" className="card-re">
                            <div className="card-re-title ">
                                <h2 className="card-re-title1 ">Shoes-slipper</h2>
                            </div>
                            <img className="card-re-image" alt="" src={shoes} />
                            <div className="card-re-content">
                                <p className="card-re-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.</p>
                                <div className="button-container d-flex">
                                    <button className="nav-btn custom-button"> More Detail</button>
                                </div>


                            </div>
                        </div>


                    </div>





                </div>


            </div>

        </div>





    )

}

export default Retail