import React, { useEffect } from 'react';
import { TS, service } from '../Assets/index'
import '../style/service.css'
import AOS from 'aos';
import 'aos/dist/aos.css';

const Services = () => {

    useEffect(() => {

        AOS.init({ disable: 'mobile' });

    }, [])
    return (
        <div>


            <nav data-aos="zoom-in-down" data-aos-offset="200" data-aos-duration="600" className='contact-nav' style={{ backgroundColor: "white" }}>

                <div className='container' >

                    <img className='contact-title' src={service} alt='' />

                </div>

            </nav>


            <div className='mt-5'>



                <div className="container mt-5">

                    <div className="card-cont-al2">
                        <div className="card-dec">
                            <div className="card-dec-title ">
                                <h2 className="card-dec-title1 ">Dedign</h2>
                            </div>

                            <div className="card-imgandtitle">
                                <div className="col-lg-6 col-12">

                                    <img className="card-dec-image" alt="" src={TS} />

                                </div>

                                <div className="card-dec-content col-lg-6 col-12">
                                    <p className="card-dec-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.</p>


                                </div>

                            </div>

                        </div>
                    </div>

                </div>


            </div>


            <div className='mt-5'>



                <div className="container mt-5">

                    <div className="card-cont-al2">
                        <div className="card-dec">
                            <div className="card-dec-title ">
                                <h2 className="card-dec-title1 "> Development</h2>
                            </div>

                            <div className="card-imgandtitle">
                                <div className="col-lg-6 col-12">

                                    <img className="card-dec-image" alt="" src={TS} />

                                </div>

                                <div className="card-dec-content col-lg-6 col-12">
                                    <p className="card-dec-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.</p>


                                </div>

                            </div>

                        </div>
                    </div>

                </div>




            </div>




        </div>
    )
}

export default Services