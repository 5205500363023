import React, { useEffect } from 'react';
import Aos from 'aos'
import 'aos/dist/aos.css'
import '../style/Mbile.css'
import { Apptitle, TS } from "../Assets/index";


const Mobile = () => {

    useEffect(() => {

        Aos.init({ disable: 'mobile' });

    }, [])
    return (

        <div>



            <nav className="navbar3 mt-5">
                <div className="container  card-container">
                    <img className="Retail-title" src={Apptitle} alt="" />
                </div>
            </nav>


            <div className="flex-container">


                <div className='container'>
                    <div data-aos="slide-right" data-aos-offset="200"  data-aos-duration="1200" className="mb-card mt-5">

                        <div className="face back">
                            <div className="contente">

                                <span className="stars"></span>

                                <p className="desc">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.
                                </p>

                                <div className="button-container d-flex">
                                    <button className="nav-btn custom-but "> More Detail</button>
                                </div>


                            </div>
                        </div>
                        <div className="face front">
                            <b>E-Commerce App</b>
                        </div>
                    </div>
                </div>




                <div className='container'>
                    <div data-aos="slide-left" data-aos-offset="200"  data-aos-duration="1200" className="mb-card mt-5">

                        <div className="face back">
                            <div className="contente">

                                <span className="stars"></span>



                                <p className="desc">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.
                                </p>

                                <div className="button-container d-flex">
                                    <button className="nav-btn custom-but "> More Detail</button>
                                </div>


                            </div>
                        </div>
                        <div className="face front">
                            <b>Home Collection App</b>
                        </div>
                    </div>
                </div>


            </div>



            <div className="flex-container">

                <div className='container'>
                    <div data-aos="slide-right" data-aos-offset="200"  data-aos-duration="1200" className=" mb-card mt-5">

                        <div className="face back">
                            <div className="contente">

                                <span className="stars"></span>



                                <p className="desc">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.
                                </p>

                                <div className="button-container d-flex">
                                    <button className="nav-btn custom-but "> More Detail</button>
                                </div>


                            </div>
                        </div>
                        <div className="face front">
                            <b>Mobile Billing App</b>
                        </div>
                    </div>
                </div>

                <div className='container'>
                    <div data-aos="slide-left" data-aos-offset="200"  data-aos-duration="1200" className="  mb-card mt-5">

                        <div className="face back">
                            <div className="contente">

                                <span className="stars"></span>



                                <p className="desc">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.
                                </p>

                                <div className="button-container d-flex">
                                    <button className="nav-btn custom-but "> More Detail</button>
                                </div>


                            </div>
                        </div>
                        <div className="face front">
                            <b>Chit Online App</b>
                        </div>
                    </div>
                </div>

            </div>


            <div className="container mt-5 mb-5">

                <div className="card-cont-al1">



                    <div data-aos="flip-right" data-aos-offset="200"  data-aos-duration="1500" className="card-mob">
                        <div className="card-mob-title ">
                            <h2 className="card-mob-title1 "> Jewellery Whole Sales</h2>
                        </div>

                        <div className="card-imgandtitle">
                            <div className="col-lg-6 col-12">

                                <img className="card-jwe-image" alt="" src={TS} />

                            </div>

                            <div className="card-jwe-content col-lg-6 col-12">
                                <p className="card-jwe-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.</p>
                                <div className="button-container d-flex">
                                    <button className="nav-btn custom-but"> More Detail</button>
                                </div>

                            </div>

                        </div>

                    </div>


                </div>

            </div>



        </div>



    )
}


export default Mobile