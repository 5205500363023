
import '../style/Conntact.css'
import axios from 'axios';
// apiService.js

// import { Video } from "../Assets"
import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom'
import { Contactpin, Contactus, Contbanner } from '../Assets/index';

const Contact = () => {

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://embed.tawk.to/5daef1b0df22d91339a06cbe/default';
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');

    // Append the script to the document's head
    document.head.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);



  // useEffect(() => {
  //   const animateCounters = () => {
  //     const counters = document.querySelectorAll('.counter');

  //     counters.forEach(counter => {
  //       const updateCounter = () => {
  //         const target = +counter.getAttribute('data-to');
  //         const count = +counter.innerText;
  //         const speed = +counter.getAttribute('data-speed') || 2000; // Default speed: 2000ms
  //         const increment = target / speed;

  //         if (count < target) {
  //           counter.innerText = Math.ceil(count + increment);
  //           setTimeout(updateCounter, 1);
  //         } else {
  //           counter.innerText = target;
  //         }
  //       };

  //       updateCounter();
  //     });
  //   };

  //   animateCounters();
  // }, []);



  const emailAddress = 'agnisoftwaresolutions@gmail.com'; // Replace with the email address you want to link

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    city: '',
    remark: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    mobile: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const validateForm = () => {
      let valid = true;
      const newErrors = { ...errors };

      if (!/^[a-zA-Z\s]+$/.test(formData.name)) {
        newErrors.name = 'Only characters are allowed in the Name field.';
        valid = false;
      } else {
        newErrors.name = '';
      }

      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
        newErrors.email = 'Invalid email format.';
        valid = false;
      } else {
        newErrors.email = '';
      }

      if (!/^\d{10}$/.test(formData.mobile)) {
        newErrors.mobile = 'Mobile number must be exactly 10 digits.';
        valid = false;
      } else {
        newErrors.mobile = '';
      }

      setErrors(newErrors);
      return valid;
    };

    try {
      const response = await axios.post('http://192.168.0.104:8012/contact/us', formData);

      if (response.status === 200) {
        // Clear the form after successful submission
        setFormData({
          name: '',
          email: '',
          mobile: '',
          city: '',
          remark: '',
        });
        setErrors({}); // Clear any previous errors
      } else {
        // Handle any API errors here
      }

      console.log('Server response:', response.data);
    } catch (error) {

      console.error('Error:', error);
    }



    if (validateForm()) {
      // Submit the form data to your server or perform any other action here
      console.log('Form is valid. Submitting data:', formData);
    } else {
      console.log('Form has errors. Please correct them.');
    }
  };








  return (
    <div className=' '>

      {/* <div className="container">
        <h2>Responsive Web Design</h2>
        <p>Web design is responsive design. Responsive web design is web design, done right.</p>
        <a href="#" className="btn btn-fill">Learn More</a>
        <div className="row">
          <div className="col-sm-3 col-xs-6">
            <div className="counter-item">
              <p className="counter" data-to="377" data-speed="2000">0</p>
              <h3>Coffee Cups</h3>
            </div>
          </div>
          <div className="col-sm-3 col-xs-6">
            <div className="counter-item">
              <p className="counter" data-to="1204" data-speed="2000">0</p>
              <h3>Projects completed</h3>
            </div>
          </div>
          <div className="col-sm-3 col-xs-6">
            <div className="counter-item">
              <p className="counter" data-to="459" data-speed="1000">0</p>
              <h3>Happy Clients</h3>
            </div>
          </div>
          <div className="col-sm-3 col-xs-6">
            <div className="counter-item">
              <p className="counter" data-to="675" data-speed="1000">0</p>
              <h3>Average Deal</h3>
            </div>
          </div>
        </div>
      </div> */}

      <div className='contactbanner'>
        <img className='contact-img' src={Contbanner} alt='' />
      </div>



      <nav className='contact-nav' style={{ backgroundColor: "white" }}>
        <div className='container' >
          {/* <div className='row'>
            <div className='col-lg-4'></div>
            <div className='col-lg-4'> */}
          {/* <h1 className="smoke-text">
                <span>C</span>
                <span>O</span>
                <span>N</span>
                <span>T</span>
                <span>A</span>
                <span>C</span>
                <span>T</span>
                <span style={{ color: "yellow" }}>US</span>
              </h1> */}
          <img className='contact-title' src={Contactus} alt='' />
        </div>
        {/* <div className='col-lg-4'></div>
          </div>
        </div> */}
      </nav>



      <div className="Cont ">
        <div className='row'>
          <div className='col-sm-6 col-12 '>
            <form className="form" onSubmit={handleSubmit}>
              {/* <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                <div className="text-danger">{errors.name}</div>
              </div> */}

              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="bi bi-person-fill"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder='Enter Your Full Name'
                  ></input>
                </div>
                <div className="text-danger">{errors.name}</div>
              </div>


              {/* <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                <div className="text-danger">{errors.email}</div>
              </div> */}


              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <div className="input-group">
                  <span className="input-group-text">
                    <i className="bi bi-envelope"></i>
                  </span>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder='Enter Your Email'
                  />
                </div>
                <div className="text-danger">{errors.email}</div>
              </div>


              {/* <div className="mb-3">
                <label htmlFor="mobile" className="form-label">
                  Mobile Number
                </label>
                <input
                  type="tel"
                  className="form-control"
                  id="mobile"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                />
                <div className="text-danger">{errors.mobile}</div>
              </div> */}


              <div className="mb-3">
                <label htmlFor="mobile" className="form-label">
                  Mobile Number
                </label>
                <div className="input-group">
                  <span className="input-group-text">
                    <i className="bi bi-telephone"></i>
                  </span>
                  <input
                    type="tel"
                    className="form-control"
                    id="mobile"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    placeholder='Enter Your Mobile Number'
                  />
                </div>
                <div className="text-danger">{errors.mobile}</div>
              </div>


              {/* <div className="mb-3">
                <label htmlFor="city" className="form-label">
                  City
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="city"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                />
              </div> */}

              <div className="mb-3">
                <label htmlFor="city" className="form-label">
                  City
                </label>
                <div className="input-group">
                  <span className="input-group-text">
                    <i className="bi bi-geo-alt-fill"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    placeholder='Enter Your City'
                  />
                </div>
              </div>



              <div className="mb-3">
                <label htmlFor="remark" className="form-label">
                  Subject/Remark
                </label>
                <textarea
                  className="form-control"
                  id="remark"
                  name="remark"
                  value={formData.remark}
                  onChange={handleChange}
                  placeholder='Your Remark'
                />
              </div>
              <div className="d-flex justify-content-center mt-3">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
          {/* <div className='col-sm'> </div> */}




          <div className='col-sm-6 col-12'>
            <form className="form1">


              <div>
                <img className='cont-pin' src={Contactpin} alt=''/>
              </div>
              <div className=''>

                <h3 >2A ,Thillai Nagar, Sammattipuram, </h3>




              </div>
              <div className=''>



                <h3>Madurai - 625016 </h3>


              </div>

              <br />

              <div>

                {/* <p>
                  <Link style={{ color: "black", textDecoration: "none" }} className=''>
                    WWW.agnisoftware.com</Link>
                </p> */}

                <p>
                  <Link to={`mailto:${emailAddress}`} style={{ color: "black", textDecoration: "none" }}>
                    agnisoftwaresolutions@gmail.com</Link>
                </p>


              </div>

              <div className='d-flex mx-md-n5'>
                {/* <div className='d-flex px-md-5'>
                  <div className=''>
                    <img src={Whatsapp} alt='' />
                  </div>
                  <div>
                    <h5 className='mt-3'>9159152272</h5>
                  </div>
                </div> */}


                <div className='d-flex px-md-5'>
                  {/* <div className=''>
                    <img src={Phone} alt='' />
                  </div> */}
                  <div>
                    {/* <h5 className='mt-3'>9159152272</h5> */}
                    <h5 className='mt-3'>+ 0452-4524203</h5>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>


      <div className="mapouter">
        <div className="gmap_canvas">
          <iframe className="gmap_iframe ps-3" width={"99%"} height={"500px"} frameborder={"0"} scrolling={"no"} marginheight={"0"} marginwidth={"0"} src="https://maps.google.com/maps?width=650&amp;height=500&amp;hl=en&amp;q=Agni software solutions madurai&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
          </iframe>
        </div>
      </div>




    </div>
  );
}

export default Contact;