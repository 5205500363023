import React from 'react'
import { Empty } from '../Assets/index'


const Demo = () => {
    return(
        <div>

            <img className='Service-img' src={Empty} alt=''/>
       
        </div>
    )
}

export default Demo