import React, { useEffect } from 'react';
import Aos from 'aos'
import 'aos/dist/aos.css'
import { Jewellerym, TS } from "../Assets/index";
import '../style/Jewellery.css'


const Jewellery = () => {



    useEffect(() => {

        Aos.init({ disable: 'mobile' });

    }, [])
    return (
        <div>


            <nav className="navbar3 mt-5">
                <div className="container  card-container">
                    <img className="Retail-title" src={Jewellerym} alt="" />
                </div>
            </nav>

            
            <div className="container mt-5">

                <div className="card-cont-al1">



                    <div data-aos="fade-right" data-aos-delay="100" data-aos-duration="1600" className="card-jwe">
                        <div className="card-jwe-title ">
                            <h2 className="card-jwe-title1 "> Jewellery Whole Sales</h2>
                        </div>

                        <div className="card-imgandtitle">
                            <div className="col-lg-6 col-12">

                                <img className="card-jwe-image" alt="" src={TS} />

                            </div>

                            <div className="card-jwe-content col-lg-6 col-12">
                                <p className="card-jwe-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.</p>
                                <div className="button-container d-flex">
                                    <button className="nav-btn custom-but"> More Detail</button>
                                </div>

                            </div>

                        </div>

                    </div>


                </div>

            </div>

            <div className="container mt-5">

                <div className="card-cont-al1">
                    <div data-aos="fade-left" data-aos-delay="100" data-aos-duration="1600" className="card-jwe">
                        <div className="card-jwe-title ">
                            <h2 className="card-jwe-title1 ">Jewellery Retail</h2>
                        </div>

                        <div className="card-imgandtitle">
                            <div className="col-lg-6 col-12">

                                <img className="card-jwe-image" alt="" src={TS} />

                            </div>

                            <div className="card-jwe-content col-lg-6 col-12">
                                <p className="card-jwe-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.</p>
                                <div className="button-container d-flex">
                                    <button className="nav-btn custom-but"> More Detail</button>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>

            </div>

            <div className="container mt-5">
                <div className="card-cont-al1">
                    <div data-aos="fade-right" data-aos-delay="100" data-aos-duration="1600" className="card-jwe">
                        <div className="card-jwe-title ">
                            <h2 className="card-jwe-title1 ">Jewellery Chit</h2>
                        </div>

                        <div className="card-imgandtitle">
                            <div className="col-lg-6 col-12">

                                <img className="card-jwe-image" alt="" src={TS} />

                            </div>

                            <div className="card-jwe-content col-lg-6 col-12">
                                <p className="card-jwe-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.</p>
                                <div className="button-container d-flex">
                                    <button className="nav-btn custom-but"> More Detail</button>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>


            </div>

            <div className="container mt-5">
                <div className="card-cont-al1">
                    <div data-aos="fade-left" data-aos-delay="100" data-aos-duration="1600" className="card-jwe">
                        <div className="card-jwe-title ">
                            <h2 className="card-jwe-title1 ">Jewellery Pawn Broking</h2>
                        </div>

                        <div className="card-imgandtitle">
                            <div className="col-lg-6 col-12">

                                <img className="card-jwe-image" alt="" src={TS} />

                            </div>

                            <div className="card-jwe-content col-lg-6 col-12">
                                <p className="card-jwe-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis ante sit amet tellus ornare tincidunt.</p>
                                <div className="button-container d-flex">
                                    <button className="nav-btn custom-but"> More Detail</button>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    )
}






export default Jewellery