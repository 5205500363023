import React from 'react';
import '../style/Loding.css'

const Loading = () => (


<div className='container loding'>
  <div className='loader'>
    <div className='loader--dot'></div>
    <div className='loader--dot'></div>
    <div className='loader--dot'></div>
    <div className='loader--dot'></div>
    <div className='loader--dot'></div>
    <div className='loader--dot'></div>
    <div className='loader--text'></div>
  </div>
</div>

);

export default Loading;