import React from 'react';
import CountUp from 'react-countup';
import { Meet } from "../Assets/index"
import '../style/home1.css'
import VisibilitySensor from 'react-visibility-sensor';



const Counter = ({ to, duration, start, decimals }) => {
  return (
    <VisibilitySensor partialVisibility>
    {({ isVisible }) => (
      <CountUp end={isVisible ? to : 0} duration={duration} start={start} decimals={decimals} />
    )}
  </VisibilitySensor>
  );
};

const Experience = () => {







  return (
    <div>
      <div className=' I_T  justify-content-center'>

        <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner ">



            <div className="carousel-item active" data-bs-interval="10000">
              <div className='demo'>
                <img src={Meet} className="img-log d-block" alt="..." />
                <div className="card1" style={{ width: "18rem" }}>
                  <div className="card-body1">
                    <h3 className="card-title1">Software Tester </h3>

                    <p className="card-text1">Up-to-date knowledge of software test design and testing methodologies,Working knowledge of test techniques and compatibility with various software programs,Responsible for creating and reviewing test strategy, Test plan and interaction with development team and Project Coordinator.</p>

                  </div>
                </div>
                <div className="card1" style={{ width: "18rem" }}>
                  <div className="card-body1">
                    <h3 className="card-title1">Android Developer</h3>

                    <p className="card-text1">Strong knowledge of Android SDK, different versions of Android, and how to deal with different screen sizes, Familiarity with RESTful APIs to connect Android applications to back-end services, Strong knowledge of Android UI design principles, patterns, and best practices</p>

                  </div>
                </div>
              </div>
            </div>



            <div className="carousel-item" data-bs-interval="2000">
              <div className='demo'>
                <img src={Meet} className="img-log d-block" alt="..." />
                <div className="card1" style={{ width: "18rem" }}>
                  <div className="card-body1">
                    <h3 className="card-title1">PHP Developer</h3>

                    <p className="card-text1">Knowledge of PHP web frameworks including Laravel, and CodeIgniter, Basic knowledge of front-end technologies including CSS3, JavaScript, and HTML5, Strong understanding of the web development cycle and programming techniques and tools, Understanding of object-oriented PHP programming.</p>

                  </div>
                </div>
                <div className="card1" style={{ width: "18rem" }}>
                  <div className="card-body1">
                    <h3 className="card-title1">Card title</h3>

                    <p className="card-text1">Up-to-date knowledge of design software like Figma, Adobe Illustrator , Adobe XD and Photoshop, Portfolio of design projects for both Websites and Mobile Applications.Knowledge of wireframe tools (e.g. Wireframe.cc and InVision)</p>

                  </div>
                </div>
              </div>
            </div>



            <div className="carousel-item">
              <div className='demo'>
                <img src={Meet} className="img-log d-block" alt="..." />
                <div className="card1" style={{ width: "18rem" }}>
                  <div className="card-body1">
                    <h3 className="card-title1">UI Developer</h3>

                    <p className="card-text1">Proficient knowledge of web markup, including HTML5, CSS3, Bootstrap and Javascript, Reactjs and Angular, Strong understanding of front-end coding and development technologies. Proficient understanding of client-side scripting and JavaScript frameworks, including jQuery.</p>

                  </div>
                </div>
                <div className="card1" style={{ width: "18rem" }}>
                  <div className="card-body1">
                    <h3 className="card-title1">Business Development Executive</h3>

                    <p className="card-text1">Ability to analyze market trends and strong communication skills. Understanding of company’s goals and business model. Nurturing relationships with clients</p>

                  </div>
                </div>
              </div>

            </div>


            <div className="carousel-item">
              <div className='demo'>
                <img src={Meet} className="img-log d-block" alt="..." />
                <div className="card1" style={{ width: "18rem" }}>
                  <div className="card-body1">
                    <h3 className="card-title1">Content writer</h3>

                    <p className="card-text1">Optimizing content for search engines using keywords, meta tags and other SEO techniques. Excellent written communication skills and Immaculate understanding of grammar. Knowledge of social media trends and best practices Creativity and a passion for writing and storytelling</p>

                  </div>
                </div>
                <div className="card1" style={{ width: "18rem" }}>
                  <div className="card-body1">
                    <h3 className="card-title1">SEO</h3>

                    <p className="card-text1">Perform keyword research on a regular basis. Develop optimization strategies that increase the company's search engine results rankings. Keep abreast of emerging SEO trends and techniques to ensure our website remains competitive.</p>

                  </div>
                </div>
              </div>

            </div>


          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>





      </div>

      <div className="sub">

        <div className="row sub-cont">
          <div className="col-lg-1"></div>

          <div className="col-lg-4  ">

            <p className="title-1">Subscribe To Hear About New <br /> Arrivals And Latest Offers.</p>


          </div>

          <div className="col-lg-5 ">

            <div className="d-flex sub1">

              <input
                type="email"
                className="form-control"
                id="email"
                placeholder='Enter Your Email'
              />


              <button type="button" className="btn-sub">SUBSCRIBE</button>


            </div>

          </div>

        </div>


        <div className='counter-progress'>

          <div className="container">
            <div className="row count-bar ms-5">
            
              <div className="col-sm-3 col-xs-6 ">
                <div className="counter-item">
                  <h2><Counter to={130} duration={2} start={0} decimals={0} />+</h2>
                  
                  <h6> Client</h6>
                </div>
              </div>
              <div className="col-sm-3 col-xs-6">
                <div className="counter-item">
                  <h2>  <Counter to={150} duration={2} start={0} decimals={0} />+</h2>
                

                  <h6>Projects</h6>
                </div>
              </div>
              <div className="col-sm-3 col-xs-6">
                <div className="counter-item">
                  <h2> <Counter to={30} duration={1} start={0} decimals={0} />+</h2>
                  <h6>Location</h6>
                </div>
              </div>
              <div className="col-sm-2 col-xs-6">
                <div className="counter-item">
                  <h2>  <Counter to={10} duration={1} start={0} decimals={0} />+</h2>
                  <h6>Live Project</h6>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

    </div>

  )
}


export default Experience