import React, { useEffect } from 'react';
import '../style/about.css'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { Aboutbanner, Aboutus, Banner1, } from '../Assets/index'
import { Link } from 'react-router-dom'



const About = () => {

    
useEffect(() => {

    Aos.init({disable: 'mobile'});

},[])
    return (
        <div className='About-all'>

            {/* 

            <nav>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-4'></div>
                        <div className='col-lg-4'>
                            <h1 className="smoke-text">
                                <span>A</span>
                                <span>B</span>
                                <span>O</span>
                                <span>U</span>

                                <span style={{ color: "yellow" }}>T</span>
                            </h1>
                        </div>
                        <div className='col-lg-4'></div>
                    </div>
                </div>
            </nav>



            <div className='about-content'>





                <div className='col-10'>

                    <div className='container'>

                        <div className='row'>

                            <div>
                                <div className=''>
                                    <nav className="nav-about1 d-flex p-3 bd-highligh">
                                        <label className='about-lable'>ABOUT AGNI SOFTWARE SOLUTIONS</label>
                                    </nav>
                                </div>

                                <div>
                                    <img className='about-img' src={Aboutus} alt='' />
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

                <div className='col-2'>

                    <div className='container'>

                        <div className='row'>

                            <div>
                                <div className='service-cont'>
                                    <nav className="nav-about2 p-3 bd-highligh">
                                        <label className='about-lable'>SERVICES</label>
                                    </nav>

                                    <div className='link-div'>
                                        <Link className="nav-link mt-5">Agni Gold</Link> <br/>
                                        <Link className="nav-link">Agni Retail</Link> <br />
                                        <Link className="nav-link">Agni Pharam</Link> <br />
                                        <Link className="nav-link">Responsive Web Designs</Link> <br />
                                        <Link className="nav-link">Website Maintance</Link> <br />
                                        <Link className="nav-link">E - Commerce Website</Link> <br />
                                        <Link className="nav-link"> Website Redesign</Link> <br />
                                        <Link className="nav-link">Web Hosting</Link> <br />
                                        <Link className="nav-link">PHP Web development</Link> <br />
                                        <Link className="nav-link">Bulk SMS Software</Link> <br />
                                        <Link className="nav-link">Android Application</Link> <br />
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>
                



            </div>

            <div className='img-d '>
            <img src={Banner1} className="card-img d-block w-100" alt="..." />

            </div> */}



            {/* <div className='About-Banner'>
                <img className='About-img1' src={Aboutbanner} alt="" />
            </div> */}


            <div className='container About-cont'>

                <div data-aos="fade-up"  data-aos-duration="1000" className='hero-content'>

                    <h2>
                        Welcome To Our Website
                    </h2>

                    <p>
                        Discover The Latest Trends and Innovations in technology,design,
                        and more.our team of experts brings you the best content and
                        insights to help you stay ahead of the curve
                    </p>


                    <button className="nav-btn custom-but abou-btn">Learn More</button>

                </div>

                <div data-aos="fade-right"  data-aos-duration="1000" className='Hero-image'>
                    <img className='about-img' src={Aboutus} alt='' />
                </div>


            </div>






        </div>
    )
}

export default About