import React, { useEffect } from 'react';
import '../style/home.css'
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Example, Retail, E_com, Software } from '../Assets/map';
import { Link } from "react-router-dom"
import 'animate.css';
import Experience from './home1';
import { AC, AG, AR, AS, Banner1, Banner2, Comcard, Title1, Title2, Title3, Title4, newh } from '../Assets/index';

const Home = () => {

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://embed.tawk.to/5daef1b0df22d91339a06cbe/default';
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');

    // Append the script to the document's head
    document.head.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);



  // const settings = {
  //   dots: true,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 2,
  //   slidesToScroll: 2,
  //   initialSlide: 0,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //         infinite: true,
  //         dots: true
  //       }
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //         initialSlide: 2
  //       }
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1
  //       }
  //     }
  //   ]
  // };


  return (

    <div className='body'>

      <div className='' >

        <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" data-bs-interval="5000">
          <div className="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1" ></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2" ></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3" ></button>

          </div>
          <div className="carousel-inner">
            <div className=" item carousel-item active  ">
              <img src={Banner1} className="banner card-img d-block" alt="..." />
              {/* <div className='card-img-overlay carousel-caption1'>
                <h2 className='animate__animated animate__bounceInRight' style={{ animationDelay: "1s", color:"white" }}> Empowering your <span  style={{  color:"white" }}>Business</span></h2>
                <h3 className='animate__animated animate__backInLeft' style={{ animationDelay: "2s",color:"white"  }}>With Custom Software Solutions</h3>
                <p className='animate__animated animate__bounceInRight' style={{ animationDelay: "3s" }}><button className='clsbtn' href='/'>Contact Us</button></p>
              </div> */}
            </div>
            <div className="item carousel-item">
              <img src={Banner2} className="banner card-img d-block " alt="..." />
              <div className='card-img-overlay carousel-caption1'>

                <h2 className='animate__animated animate__slideInDown' style={{ animationDelay: "1s", }}> we are <span>Developers</span></h2>
                <h3 className='animate__animated animate__backInLeft' style={{ animationDelay: "2s" }}>We Design and Development</h3>
                <p className='animate__animated animate__zoomIn' style={{ animationDelay: "3s" }}><button className='clsbtn' href='./'>Contact Us</button></p>
              </div>
            </div>
            <div className="item carousel-item">
              <img src={Banner1} className="banner card-img d-block w-100" alt="..." />
              <div className='card-img-overlay carousel-caption1'>

                <h2 className='animate__animated animate__zoomIn' style={{ animationDelay: "1s", color:"white" }}> we are <span style={{  color:"white" }}>Application</span></h2>
                <h3 className='animate__animated animate__fadeInLeft' style={{ animationDelay: "2s",color:"white"  }}>We Design and Development</h3>
                <p className='animate__animated animate__bounceInRight' style={{ animationDelay: "3s" }}><button className='clsbtn' href='/'>Contact Us</button></p>
              </div>
            </div>
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>



        </div>

        {/* <img className="newhome" src={newh}  alt=''/> */}

        {/* /////GOLD//// */}
        <div className='All-card '>
          <div className='bg1'>




            <div className="navbar3 ">
              <div className="container">
                <img src={Title1} className='title-img1' alt='' />
              </div>
            </div>





            <div className="navbar3 ">
            </div>



            <div className='container card-container'>
              <div className='App'>
                {/* <Slider{...settings}> */}
                {Example.map((item) => (

                  <div className='d-flex'>
                    <div className="card text-bg-dark">
                      <img src={AG} className="card-img" alt="..." />
                    </div>

                    <div className="card text-bg-dark">
                      <img src={Comcard} className="card-img" alt="..." />
                      <div className="card-img-overlay">
                        <div className='content'>
                          <h3 className="card-title">{item.title}</h3>
                          <h5 className="card-sub-title">{item.subtitle}</h5>

                          <div className='phara'>
                            <p className="card-text">{item.content1}</p>
                            <p className="card-text">{item.content2}</p>
                            <p className="card-text">{item.content3}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                ))}


                {/* </Slider> */}

              </div>

            </div>


            <nav className="navbar3 " style={{ backgroundColor: "white" }}>
              <div className="container-fluid">
                <span className="navbar-brand mb-0 h1"></span>
              </div>
            </nav>
          </div>

          {/* /////Retail//// */}

          <div className='bg '>
            <nav className="navbar3 ">
              <div className="container  card-container">
                <img src={Title2} className='title-img1' alt='' />
              </div>
            </nav>
            <div className="navbar3 ">
            </div>
            <div className='container'>
              <div className='App d-flex'>
                {/* <Slider{...settings}> */}
                {Retail.map((item) => (

                  <div className='d-flex'>
                    <div className="card text-bg-dark">
                      <img src={AR} className="card-img" alt="..." />
                    </div>

                    <div className="card text-bg-dark">
                      <img src={Comcard} className="card-img" alt="..." />
                      <div className="card-img-overlay">
                        <div className='content'>
                          <h3 className="card-title">{item.title}</h3>
                          <h5 className="card-sub-title">{item.subtitle}</h5>

                          <p className="card-text">{item.content1}</p>
                          <p className="card-text">{item.content2}</p>
                          <p className="card-text">{item.content3}</p>
                        </div>
                      </div>
                    </div>
                  </div>




                ))}

                {/* </Slider> */}

              </div>

            </div>


            <nav className="navbar3" style={{ backgroundColor: "white" }}>
              <div className="container-fluid">
                <span className="navbar-brand mb-0 h1"></span>
              </div>
            </nav>
          </div>


          {/* /////E-com//// */}

          <div className='bg '>
            <nav className="navbar3 ">
              <div className="container ">
                <img src={Title3} className='title-img1' alt='' />
              </div>
            </nav>
            <div className="navbar3 ">
            </div>
            <div className='container  card-container'>
              <div className='App d-flex'>
                {/* <Slider{...settings}> */}
                {E_com.map((item) => (


                  // <div className="card  text-white">
                  //   <img src={item.linkimg} className="card-img" alt="" />
                  //   <div className="card-img-overlay">
                  //     <div className='content'>
                  //       <h3 className="card-title">{item.title}</h3>
                  //       <h5 className="card-sub-title">{item.subtitle}</h5>

                  //       <p className="card-text">{item.content1}</p>
                  //       <p className="card-text">{item.content2}</p>
                  //       <p className="card-text">{item.content3}</p>
                  //     </div>
                  //   </div>
                  // </div>


                  <div className='d-flex'>
                    <div className="card text-bg-dark">
                      <img src={AC} className="card-img" alt="..." />
                    </div>

                    <div className="card text-bg-dark">
                      <img src={Comcard} className="card-img" alt="..." />
                      <div className="card-img-overlay">
                        <div className='content'>
                          <h3 className="card-title">{item.title}</h3>
                          <h5 className="card-sub-title">{item.subtitle}</h5>

                          <p className="card-text">{item.content1}</p>
                          <p className="card-text">{item.content2}</p>
                          <p className="card-text">{item.content3}</p>
                        </div>

                      </div>
                    </div>
                  </div>







                ))}

                {/* </Slider> */}

              </div>

            </div>


            <nav className="navbar3" style={{ backgroundColor: "white" }}>
              <div className="container-fluid">
                <span className="navbar-brand mb-0 h1"></span>
              </div>
            </nav>
          </div>


          {/* /////Software//// */}

          <div className='bg '>
            <nav className="navbar3 ">
              <div className="container">
                <img src={Title4} className='title-img1' alt='' />
              </div>
            </nav>
            <div className="navbar3 ">
            </div>
            <div className='container  card-container'>
              <div className='App d-flex'>
                {/* <Slider{...settings}> */}

                {Software.map((item) => (

                  <div className='d-flex'>
                    <div className="card text-bg-dark">
                      <img src={AS} className="card-img" alt="..." />
                    </div>

                    <div className="card text-bg-dark">
                      <img src={Comcard} className="card-img" alt="..." />
                      <div className="card-img-overlay">
                        <div className='content'>
                          <h3 className="card-title">{item.title}</h3>
                          <h5 className="card-sub-title">{item.subtitle}</h5>

                          <div className='phara'>
                            <p className="card-text">{item.content1}</p>
                            <p className="card-text">{item.content2}</p>
                            <p className="card-text">{item.content3}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                ))}

                {/* </Slider> */}

              </div>

            </div>


            <nav className="navbar3" style={{ backgroundColor: "white" }}>
              <div className="container-fluid">
                <span className="navbar-brand mb-0 h1"></span>
              </div>
            </nav>
          </div>
        </div>


        {/* /////SCHEDULE //// */}


        <div className='schedule '>

          <div className='container'>
            <div className='row'>
              <div className='col-2'></div>
              <div className='col-8'>
                <div className=' sch-cune'>

                  <h2><b>Schedule a Meeting With Us!</b></h2>
                  <p>Let's discuss Your idea and build something amazing together.</p>
                  <Link to="Contactus">
                    <button>Get in Touch</button>
                  </Link>


                </div>

              </div>
              <div className='col-2'></div>
            </div>
          </div>

        </div>


        {/* /////JOIN EXP //// */}


        <Experience />
      </div>

    </div>

  )
}

export default Home