import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'
import React, { useState, useEffect, useLayoutEffect } from 'react';
import '../src/style/App.css'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Header from './components/header';
import Footer from './components/Footer';
import Home from './pages/home';
import About from './pages/about';
import Loading from './pages/Loding';
import Contact from './pages/Contact';
import Services from './pages/services';
import Prodect from './pages/Prodects';
import Careers from './pages/careers';
import Demo from './pages/demo';
import Retailmt from './pages/Retail';
import Jewellery from './pages/Jewellery';
import Mobile from './pages/Mobile';
import Finance from './pages/Finance';
// import Login from './pages/Login';


function App() {



  const [loading, setLoading] = useState(true);

  useEffect(() => {

    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, []);



  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    window.onscroll = () => {
      if (document.documentElement.scrollTop > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };





  return (
    <Router>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Header />
          {/* <Login/> */}
          <Routes>
            <Route path="/" element={<> <ScrollToTop path="/" /><Home /></>} />
            <Route path="products" element={<><ScrollToTop path="products" /><Prodect /></>} />
            <Route path="careers" element={<><ScrollToTop path="careers" /><Careers /></>} />
            <Route path="Contactus" element={<><ScrollToTop path="/Contactus" /><Contact /></>} />
            <Route path="Aboutus" element={<><ScrollToTop path="/Aboutus" /><About /></>} />
            <Route path="services" element={<><ScrollToTop path="services" /><Services /></>} />
            <Route path="demo" element={<><ScrollToTop path="demo" /><Demo /></>} />
            <Route path="Retailmt" element={<><ScrollToTop path="Retailmt" /><Retailmt/></>} />
            <Route path="Jewellerymt" element={<><ScrollToTop path="Jewellerymt"/><Jewellery/></>} />
            <Route path="finance" element={<><ScrollToTop path="finance"/><Finance/></>} />
            <Route path="MobileApl" element={<><ScrollToTop path="MobileApl"/><Mobile/></>}/>


          </Routes>

          <button
            className={`scroll-to-top-btn ${isVisible ? 'show' : 'hide'}`}
            onClick={scrollToTop}
          >
            ▲
          </button>
          <Footer />
        </>
      )}
    </Router>
  );
}



function ScrollToTop() {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}


export default App;
