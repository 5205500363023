import React from "react";
import { Empty } from "../Assets";


const Prodect = () =>{
    return(
        <div>

            <img className='Service-img' src={Empty} alt=''/>
       
        </div>
    )
}

export default Prodect